import { createContext, useMemo, useContext, useState, ReactNode } from 'react';
import noop from 'lodash/noop';
import { FocusTrap } from 'focus-trap-react';

const FocusTrapContext = createContext({
  isTrapPaused: false,
  pauseTrap: noop,
  unpauseTrap: noop,
});

interface FocusTrapProviderProps {
  fallbackFocusElementId?: string;
  children: ReactNode;
}

export const FocusTrapProvider = ({
  fallbackFocusElementId,
  children,
}: FocusTrapProviderProps) => {
  const [isTrapPaused, setIsTrapPaused] = useState(false);

  const contextValue = useMemo(
    () => ({
      isTrapPaused,
      pauseTrap: () => setIsTrapPaused(true),
      unpauseTrap: () => setIsTrapPaused(false),
    }),
    [isTrapPaused],
  );

  return (
    <FocusTrapContext.Provider value={contextValue}>
      <FocusTrap
        paused={isTrapPaused}
        focusTrapOptions={{
          fallbackFocus: fallbackFocusElementId,
        }}
      >
        {children}
      </FocusTrap>
    </FocusTrapContext.Provider>
  );
};

export const useFocusTrap = () => useContext(FocusTrapContext);
